body {
  font-family: $ff;
  font-size: rem-calc(18);
  font-weight: 400;
  color: $black;
}

p {
  font-family: $ff;
  font-size: rem-calc(18);
  line-height: rem-calc(28);
}

a {
  color: #e84855;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
  font-family: $ff;
}

h1 {
  font-size: rem-calc(28);
  line-height: rem-calc(38);
  font-weight: $bold;

  @include mq(800) {
    font-size: rem-calc(38);
    line-height: rem-calc(48);
  }
}

h2 {
  font-size: rem-calc(36);
  line-height: rem-calc(46);
  text-transform: uppercase;
}

h3 {
  font-size: rem-calc(24);
  line-height: rem-calc(32);
}

.content-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrap {
  width: 100%;
  max-width: 900px;
  padding: 20px 20px;

  @include mq(1200) {
    padding: 80px 20px;
  }

  .image {
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 6px;

    @include mq(700) {
      height: 500px;
      width: 500px;
    }

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .content {
    width: 100%;
    text-align: left;
    padding: 40px 20px;
    background-color: #fcba03;
    margin-left: auto;
    position: relative;
    border-radius: 6px;
    margin-top: 20px;

    @include mq(700) {
      margin-top: -200px;
      width: 500px;
      padding: 40px;
      min-height: 500px;
    }
  }

  ul.services {
    padding: 0px 0px 40px 20px;

    > li {
      padding-top: 5px;
    }
  }

  .intro {
    padding-top: 20px;
    font-size: rem-calc(18);
    font-weight: $bold;
    padding-bottom: 0px;
  }

  .contacts {
    padding-top: 10px;
    text-transform: uppercase;
    font-size: rem-calc(16);

    p,
    a {
      display: block;
      text-transform: uppercase;
      font-size: rem-calc(16);
    }
  }

  .location {
    font-weight: $bold;
    padding-top: 10px;
    text-transform: uppercase;
    font-size: rem-calc(16);
  }
}

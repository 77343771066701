/* ------------------------------------------
   tools.MIXINS
------------------------------------------ */

@mixin clearfix() {
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin un-clearfix() {
  &:before, &:after {
    content: none;
    display: auto;
  }
  &:after {
    clear: none;
  }
}

// Image Replacement

@mixin ir() {
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  // background: url(test.png);
  // width: 300px;
  // height: 75px;
}

// Hide only visually, but have it available for screen readers:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility

@mixin visuallyhidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


//  ## Media query and orietation mixins
//  ==================================================
//  http://alwaystwisted.com/post.php?s=2013-04-01-my-media-query-mixin


@mixin mq($point, $query1: min, $query2: width) {
  @media screen and (#{$query1}-#{$query2}: strip-unit($point) / 16 +em) {
    @content;
  }
}

@mixin orient($orientation: landscape) {
  @media (orientation : $orientation) {
    @content;
  }
}
